import type {
  ActivityModel,
  ActivityRecord,
  ChartDataModel,
  ICreateUserResponse,
  IUserModel,
  StakeModel,
  TReferralWithdrawPayload,
  TReferralWithdrawResponse,
  TSubscribeEmailModel,
  TSubscribeEmailResponse,
  TValidateAddressModel,
  IRegisterUserWithReferral,
  IUserSubscribers,
  IPercentAPRResponse,
  ITokenModel,
  TReferralLevelUser
} from '../types/apiService';

export default class PearlApiService {
  public static async getTakeProfitLevel(url: string) {
    const response = await fetch(url + '/takeprofit/level');
    const res = await response.json();
    return res;
  }

  public static async config(api: string) {
    const response = await fetch(api + '/config');
    const res = await response.json();
    return res;
  }

  public static async pushActivity(url: string, data: ActivityModel) {
    const response = await fetch(url + '/activity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const res = await response.json();
    return res;
  }

  public static async getActivity(
    url: string,
    sender: string,
    token: string,
    offset: number = 0,
    limit: number = 50,
    sort: string = 'desc'
  ) {
    const params = new URLSearchParams({
      sender,
      token,
      offset: offset.toString(),
      limit: limit.toString(),
      sort: sort
    });

    const response = await fetch(url + '/v1/activity?' + params.toString());
    const res: { total: number; count: number; items: ActivityRecord[] } = await response.json();
    return res;
  }

  public static async getStake(url: string, sender: string, token: string, offset = 0, limit = 50) {
    const params = new URLSearchParams({
      sender,
      token,
      offset: offset.toString(),
      limit: limit.toString()
    });

    const response = await fetch(url + '/v1/stake?' + params.toString());
    const res = await response.json();
    return res.items as StakeModel[];
  }

  public static async getStatisticRate(url: string, from: string, to: string): Promise<ChartDataModel> {
    const response = await fetch(url + `/statistics/rate?from=${from}&until=${to}`);
    const res = await response.json();
    return res;
  }

  public static async getStatisticDex(url: string, from: string, to: string): Promise<ChartDataModel> {
    const response = await fetch(url + `/statistics/dex?from=${from}&until=${to}`);
    const res = await response.json();
    return res;
  }

  public static async getStatisticSold(url: string, from: string, to: string): Promise<ChartDataModel> {
    const response = await fetch(url + `/statistics/sold?from=${from}&until=${to}`);
    const res = await response.json();
    return res;
  }

  public static async getStatisticHolder(url: string, from: string, to: string): Promise<ChartDataModel> {
    const response = await fetch(url + `/statistics/holder?from=${from}&until=${to}`);
    const res = await response.json();
    return res;
  }

  public static async getStatisticBuyback(url: string, from: string, to: string): Promise<ChartDataModel> {
    const response = await fetch(url + `/statistics/buyback?from=${from}&until=${to}`);
    const res = await response.json();
    return res;
  }

  public static async getStatisticSpent(url: string, from: string, to: string): Promise<ChartDataModel> {
    const response = await fetch(url + `/statistics/spent?from=${from}&until=${to}`);
    const res = await response.json();
    return res;
  }

  public static async getStatisticPressure(url: string, from: string, to: string): Promise<ChartDataModel> {
    const response = await fetch(url + `/statistics/pressure?from=${from}&until=${to}`);
    const res = await response.json();
    return res;
  }

  public static async getAprPercent(url: string, tokenAddress: string): Promise<IPercentAPRResponse> {
    const response = await fetch(url + `/tokens/apr?tokenAddress=${tokenAddress}`);
    const res = await response.json();
    return res;
  }

  public static async validadeAddress(url: string, address: string, amount: string): Promise<TValidateAddressModel> {
    const response = await fetch(url + `/validation?address=${address}&amount=${amount}`);
    const res = await response.json();
    return { data: res, status: response.status };
  }

  public static async subscribeEmail(url: string, data: TSubscribeEmailModel): Promise<TSubscribeEmailResponse> {
    const response = await fetch(url + '/subscribe-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const res = await response.json();
    return { data: res, status: response.status };
  }

  public static async withdrawReferralReward(
    url: string,
    data: TReferralWithdrawPayload
  ): Promise<TReferralWithdrawResponse> {
    const response = await fetch(url + '/referral-program/withdrawal', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const res = await response.json();
    return { ...res, status: response.status };
  }

  public static async getTokens(url: string): Promise<ITokenModel[]> {
    const response = await fetch(url + '/tokens');
    const res = await response.json();
    return res;
  }
  public static async getTokenByKey(url: string, key: string): Promise<ITokenModel> {
    const response = await fetch(url + `/tokens/${key}`);
    const res = await response.json();
    return res;
  }
  public static async registerUserWithReferral(
    url: string,
    data: IRegisterUserWithReferral
  ): Promise<ICreateUserResponse> {
    const response = await fetch(url + '/user/sign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const res = await response.json();
    return res;
  }

  public static async getUser(url: string, address: string): Promise<IUserModel> {
    const response = await fetch(url + `/v1/user?address=${address}`);
    const res = await response.json();
    return res;
  }

  public static async getUserSubscribers(url: string, address: string): Promise<IUserSubscribers> {
    const response = await fetch(url + `/user/subscribers?address=${address}`);
    const res = await response.json();
    return res;
  }

  public static async getUserById(url: string, id: number): Promise<{ status: string; exists: boolean }> {
    const response = await fetch(url + `/user/exists?id=${id}`);
    const res = await response.json();
    return res;
  }

  public static async getUsersLevelReferrals(url: string, address: string): Promise<TReferralLevelUser[]> {
    const response = await fetch(url + `/v1/user/referral-program/rewards?address=${address}`);
    const res = await response.json();

    return res;
  }
}
